
myClass {
    color: red;
}

[dir] .avatar {
    background-color: #c3c3c3;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
}
.avatar {
    white-space: nowrap;
    position: relative;
    color: #fff;
    display: inline-flex;
    font-size: 1rem;
    vertical-align: middle;
    font-weight: 600;
}

[dir=ltr] .user-nav {
    float: left;
    margin-right: 0.8rem;
}
.user-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
[dir=ltr] .user-name {
    margin-left: 0.2rem;
    margin-bottom: 0.435rem;
    display: inline-block;
}

.user-name {
    margin-left: 0.2rem;
    margin-bottom: 0.435rem;
    display: inline-block;
}

body {
 font-size: 12px;
}