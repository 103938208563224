.header-border {
    width: 100%;
    margin-bottom: 10px;
    border: grey solid 0.01rem;
}

.header-border .heading {
    text-align: center;
    margin-top: -12px;
    font-size: 15px;
}
.header-border  .heading > span {
    background-color: white;
    box-shadow: white 11px 0px 0px,
                white -13px 0px 0px;
  }